<template>
	<div class="list-wrap">
		<van-nav-bar
			:title="title"
			right-text="退出登录"
			@click-left="clickNavBack"
			@click-right="logout"
			left-arrow
			:border="false"
			v-if="showLogout"
		></van-nav-bar>
		<van-nav-bar
			:title="title"
			@click-left="clickNavBack"
			left-arrow
			:border="false"
			v-if="!showLogout"
		></van-nav-bar>
		<div class="content">
			<van-tabs
				v-model="activeSubject"
				animated
				color="#ff7e00"
				title-active-color="#292929"
				title-inactive-color="#aaa"
				line-height="5"
				@click="onClickTab"
			>
				<van-tab
					v-for="(item, index) in subjects"
					:key="index"
					:title="item.label"
				>
					<div v-if="index===0">
						<div class="banner-area">
							<img src="@/assets/vip2/subject1_banner.png" style="width: 100%" @click="tool(dian)">
						</div>
						<div class="icon-area">
							<van-grid :border="false" :column-num="4">
								<van-grid-item v-for="(item,index) in picture" :key="index" class="main-grid">
									<div class="grid-wrap">
										<div class="main-img">
											<img :src="item.url" :alt="item.tip">
										</div>
									</div>
								</van-grid-item>
							</van-grid>
						</div>
						<div class="banner-area-all">
							<img src="@/assets/vip2/subject_banner.jpg" style="width: 100%">
						</div>
						<div class="banner-area">
							<img src="@/assets/vip2/subject1_ad.png" style="width: 100%">
						</div>
						<div class="subject-details">
							<img src="@/assets/vip2/subject_details.jpg" style="width: 100%">
						</div>
					</div>
					<div v-if="index===1">
						<div class="banner-area">
							<img src="@/assets/vip2/subject4_banner.png" style="width: 100%">
						</div>
						<div class="icon-area">
							<van-grid :border="false" :column-num="4">
								<van-grid-item v-for="(item,index) in picture" :key="index" class="main-grid">
									<div class="grid-wrap">
										<div class="main-img">
											<img :src="item.url" :alt="item.tip">
										</div>
									</div>
								</van-grid-item>
							</van-grid>
						</div>
						<div class="banner-area-all">
							<img src="@/assets/vip2/subject_banner.jpg" style="width: 100%">
						</div>
						<div class="banner-area">
							<img src="@/assets/vip2/subject4_ad.png" style="width: 100%">
						</div>
						<div class="subject-details">
							<img src="@/assets/vip2/subject_details.jpg" style="width: 100%">
						</div>
					</div>
					<div v-if="index===2">
						<div class="banner-area">
							<img src="@/assets/vip2/subject_all_banner.png" style="width: 100%">
						</div>
						<div class="icon-area">
							<van-grid :border="false" :column-num="4">
								<van-grid-item v-for="(item,index) in picture" :key="index" class="main-grid">
									<div class="grid-wrap">
										<div class="main-img">
											<img :src="item.url" :alt="item.tip">
										</div>
									</div>
								</van-grid-item>
							</van-grid>
						</div>
						<div class="banner-area">
							<img src="@/assets/vip2/subject_all_ad.png" style="width: 100%">
						</div>
						<div class="subject-details">
							<img src="@/assets/vip2/subject_details.jpg" style="width: 100%">
						</div>
					</div>
				</van-tab>
			</van-tabs>
		</div>
		<div class="switch_bot">
			<div style="text-align: center">
				<van-button class="buy_btn" :loading="loading" loading-text="购买中..." @click="selectPayType()">
					<div class="btn_area"><span class="btn-font">{{productPrice}}</span></div>
				</van-button>
			</div>
			<van-action-sheet v-model="showActionSheet" :actions="actions" @select="onSelect"/>
		</div>
		<van-dialog v-model="showBankInfo" title="银行卡转账信息">
			<van-cell-group inset>
				<van-cell title="公司名称" value="北京训美科技有限公司"/>
				<van-cell title="开户银行" value="招商银行北京分行德胜门支行"/>
				<van-cell title="银行账号" value="110943864510601"/>
			</van-cell-group>
		</van-dialog>
		<van-dialog confirm-button-text="确认" show-cancel-button cancel-button-text="取消" v-model="showCardInfo"
					title="学习卡激活" @confirm="onCardSubmit">
			<van-form>
				<van-field
					v-model="cardNo"
					name="卡号"
					label="卡号"
					placeholder="请填写卡号"
					maxlength="22"
				/>
				<van-field
					v-model="password"
					name="密码"
					label="密码"
					placeholder="请填写密码"
					maxlength="8"
				/>
			</van-form>
		</van-dialog>
	</div>
</template>

<script>
import {
	getProductPrice,
	wxAuth,
	vipPay,
	wxConfig,
	vipUserProduct,
	checkTkPayStatus,
	vipActivateCardPay
} from "../../api/user";
import Vconsole from 'vconsole'
import {Toast} from "vant";

export default {
	name: "viplist",
	data() {
		return {
			activeSubject: 0,
			subjects: [
				{
					label: "科目一",
					value: 1,
				},
				{
					label: "科目四",
					value: 4,
				},
				{
					label: "全科目",
					value: 0,
				}
			],
			productPrice: '开通尊享权益',
			dian: 0,
			title: "",
			walking: [],
			isdisplay: null, // 选中套餐索引
			picture: [
				{
					url: require('@/assets/vip2/subject_icon_01.png'),
					text: '精髓课',
					tip: '精髓课',
				},
				{
					url: require('@/assets/vip2/subject_icon_02.png'),
					text: '精选试题',
					tip: '精选试题',
				},
				{
					url: require('@/assets/vip2/subject_icon_03.png'),
					text: '真实考场',
					tip: '真实考场',
				},
				{
					url: require('@/assets/vip2/subject_icon_04.png'),
					text: '考前密卷',
					tip: '考前密卷',
				}
			],
			loading: false,
			showLogout: false,
			subject: 0,
			categoryId: 0,
			showActionSheet: false,
			actions: [{name: '学习卡激活'}, {name: '微信支付'}, {name: '银行卡支付'}],
			showBankInfo: false,
			showCardInfo: false,
			cardNo: '',
			password: ''
		};
	},
	components: {},
	created() {
		this.subject = this.$route.query.subject;
		this.categoryId = this.$route.query.categoryId;
		// 获取套餐信息
		this.getProductPrice();
		// 是否已登录
		if (localStorage.getItem('loginInTKfor_APP02') != null) {
			this.showLogout = true;
		}
	},
	computed: {
		token() {
			return JSON.parse(localStorage.getItem('loginInTKfor_APP02')).token;
		},
		appNo() {
			return sessionStorage.getItem("appno") || "APP02";
		},
	},
	mounted() {
		const openId = location.search
		if (this.$store.state.cache.screen.wx && !openId.includes('openId=')) {
			let href = window.location.href.replace('viplogin', 'viphome')
			wxAuth('', href).then(({url}) => {
				window.location.href = url
			})
		}
	},
	methods: {
		// 切换科目
		onClickTab(index, title) {
			console.log(index, title);
			this.isdisplay = index;
			this.title = title;
			this.activeSubject = index;
			let productInfo = null;
			if (index === 0) {
				productInfo = this.getProductInfo(1);
			} else if (index === 1) {
				productInfo = this.getProductInfo(4);
			} else if (index === 2) {
				productInfo = this.getProductInfo(0);
			}
			this.productPrice = productInfo.btn_txt;
		},
		getProductInfo(subject) {
			let o = null;
			for (let i = 0; i < this.walking.length; i++) {
				let product = this.walking[i];
				if (product.subject === subject) {
					o = product;
					break;
				}
			}
			if (subject === 0) {
				o.btn_txt = "一价全包：" + o.price + "元/年";
			} else {
				o.btn_txt = o.name.replace("套餐", "") + "：" + o.price + "元/年";
			}
			return o;
		},
		selectPayType() {
			this.showActionSheet = true;
		},
		onSelect(item) {
			if (item.name === '微信支付') {
				this.purchase();
			} else if (item.name === '银行卡支付') {
				this.showBankInfo = true;
			} else if (item.name === '学习卡激活') {
				this.showCardInfo = true;
				this.cardNo = '';
				this.password = '';
			}
			this.showActionSheet = false;
		},
		// 获取套餐信息
		getProductPrice() {
			getProductPrice(this.categoryId, null)
			// getProductPrice(null, null)
				.then((data) => {
					this.walking = data;
					this.title = data[data.length - 1].name;
					this.isdisplay = data.length - 1;
					this.activeSubject = data.length - 1;
					let productInfo = this.getProductInfo(0);
					this.productPrice = productInfo.btn_txt;
				})
				.catch(() => {
				});
		},
		// 点击回退
		clickNavBack() {
			this.$router.go(-1);
		},
		clickwalking(name, id) {
			this.isdisplay = id;
			this.title = name;
		},
		// 开通套餐
		purchase() {
			this.loading = true;
			const openId = location.search.split('=')[1];
			console.log('token==>', this.token);
			console.log('openId==>', openId);
			vipPay({
				token: this.token,
				payMoney: this.walking[this.isdisplay].price,
				productId: this.walking[this.isdisplay].productId,
				openId,
			}).then((data) => {
				this.loading = false;
				if (this.$store.state.cache.screen.wx) {
					this.wxPay(data)
				} else if (this.$store.state.cache.screen.isAndroid) {
					window.location.href = data.wxOrderInfo.mwebUrl
				}
			}).catch(() => {
				this.loading = false;
			})
		},
		// 微信api
		wxPay(data) {
			const _this = this
			wxConfig().then(res => {
				wx.config({
					debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
					appId: res.appId, // 必填，公众号的唯一标识
					timestamp: res.timestamp, // 必填，生成签名的时间戳
					nonceStr: res.nonceStr, // 必填，生成签名的随机串
					signature: res.signature,// 必填，签名
					jsApiList: ['chooseWXPay'] // 必填，需要使用的JS接口列表
				})
			})
			wx.ready(() => {
				wx.chooseWXPay({
					timestamp: data.wxOrderInfo.timeStamp, // 支付签名时间戳，注意微信jssdk中的所有使用timestamp字段均为小写。但最新版的支付后台生成签名使用的timeStamp字段名需大写其中的S字符
					nonceStr: data.wxOrderInfo.nonceStr, // 支付签名随机串，不长于 32 位
					package: data.wxOrderInfo.packageValue, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
					signType: data.wxOrderInfo.signType, // 微信支付V3的传入RSA,微信支付V2的传入格式与V2统一下单的签名格式保持一致
					paySign: data.wxOrderInfo.paySign, // 支付签名
					success: function () {
						// 支付成功后的回调函数
						_this.checkPayStatus(data.outTradeNo)
					},
					fail: function (error) {
						console.log('支付失败：' + error)
					}
				});
			})
		},
		/**
		 * 检测支付状态
		 */
		checkPayStatus(orderId) {
			console.log('orderId订单号=', orderId);
			checkTkPayStatus({
				studentId: JSON.parse(localStorage.getItem('loginInTKfor_APP02')).studentId,
				outTradeNo: orderId
			}).then(({payStatus}) => {
				console.log('接口返回支付状态payStatus=', payStatus);
				if (typeof payStatus !== "number") {
					payStatus = Number(payStatus)
				}
				if (payStatus === 1) {
					console.log('开始获取用户信息');
					console.log('localStorage查询参数studentId=', JSON.parse(localStorage.getItem('loginInTKfor_APP02')).studentId);
					vipUserProduct({
						id: JSON.parse(localStorage.getItem('loginInTKfor_APP02')).studentId
					}).then((data) => {
						console.log('接口返回的题库用户信息=', data);
						//const appNo = sessionStorage.getItem('appno') || 'APP02';
						localStorage.setItem('loginInTKfor_APP02', JSON.stringify({
							idcard: JSON.parse(localStorage.getItem('loginInTKfor_APP02')).idcard,
							token: JSON.parse(localStorage.getItem('loginInTKfor_APP02')).token,
							ifBind: JSON.parse(localStorage.getItem('loginInTKfor_APP02')).ifBind,
							productId: data.toString(),
							studentId: JSON.parse(localStorage.getItem('loginInTKfor_APP02')).studentId,
							organizationNo: JSON.parse(localStorage.getItem('loginInTKfor_APP02')).organizationNo
						}));
						console.log('查一下本地都存了啥=', JSON.parse(localStorage.getItem('loginInTKfor_APP02')));
						console.log('this.subject=', this.subject);
						// this.$router.push({path: '/viphome', query: {appNo}})
						if (this.subject > 0) {
							console.log('应该走这里');
							this.$router.push({
								path: "/vipchoose",
								query: {categoryId: this.categoryId, appNo: this.appNo}
							})
						} else {
							this.$router.push({
								path: "/vipchoose",
								query: {categoryId: this.categoryId, subject: 0, appNo: this.appNo}
							})
						}
					})
				} else {
					this.checkPayStatus(orderId)
				}
			})
		},
		onCardSubmit() {
			if (this.cardNo === '') {
				this.$dialog.alert({
					message: "请填写卡号",
					theme: "round-button",
					confirmButtonColor: "#2db1ac",
					width: 290,
				}).then(() => {
					this.showCardInfo = true;
				});
				return;
			}
			if (this.password === '') {
				this.$dialog.alert({
					message: "请填写密码",
					theme: "round-button",
					confirmButtonColor: "#2db1ac",
					width: 290,
				}).then(() => {
					this.showCardInfo = true;
				});
				return;
			}
			// 卡类型
			let subject = this.walking[this.isdisplay].subject;
			let cardType = null;
			if (subject === 0) {
				cardType = 3;
			} else if (subject === 1) {
				cardType = 1;
			} else if (subject === 4) {
				cardType = 2;
			}
			this.loading = true;
			vipActivateCardPay({
				cardNo: this.cardNo,
				cardPassword: this.password,
				cardType: cardType,
				token: this.token,
				productId: this.walking[this.isdisplay].productId,
			}).then(() => {
				this.loading = false;
				this.showCardInfo = false;
				vipUserProduct({
					id: JSON.parse(localStorage.getItem('loginInTKfor_APP02')).studentId
				}).then((data) => {
					Toast.success('激活成功！');
					console.log('接口返回的题库用户信息=', data);
					//const appNo = sessionStorage.getItem('appno') || 'APP02';
					localStorage.setItem('loginInTKfor_APP02', JSON.stringify({
						idcard: JSON.parse(localStorage.getItem('loginInTKfor_APP02')).idcard,
						token: JSON.parse(localStorage.getItem('loginInTKfor_APP02')).token,
						ifBind: JSON.parse(localStorage.getItem('loginInTKfor_APP02')).ifBind,
						productId: data.toString(),
						studentId: JSON.parse(localStorage.getItem('loginInTKfor_APP02')).studentId,
						organizationNo: JSON.parse(localStorage.getItem('loginInTKfor_APP02')).organizationNo
					}));
					console.log('查一下本地都存了啥=', JSON.parse(localStorage.getItem('loginInTKfor_APP02')));
					console.log('this.subject=', this.subject);
					// this.$router.push({path: '/viphome', query: {appNo}})
					if (this.subject > 0) {
						console.log('应该走这里');
						this.$router.push({
							path: "/vipchoose",
							query: {categoryId: this.categoryId, appNo: this.appNo}
						})
					} else {
						this.$router.push({
							path: "/vipchoose",
							query: {categoryId: this.categoryId, subject: 0, appNo: this.appNo}
						})
					}
				})
			}).catch(() => {
				this.loading = false;
			})
		},
		// 退出登录
		logout() {
			// 清除缓存
			sessionStorage.clear();
			localStorage.clear();
			this.$router.push({path: '/viphome', query: {appNo: 'APP02'}})
		},
		// 调试工具
		tool(dian) {
			this.dian = dian + 1
			if (this.dian >= 5) {
				new Vconsole();
			}
		}
	},
}
</script>

<style lang="less" scoped>
.subject-details {
	padding: 0 10px 10px 10px;
	margin-bottom: 50px;
}
.banner-area {
	padding: 10px;
}
.icon-area {
	width: 100%;
	height: 100%;
	padding: 15px 0;

	.main-grid {
		margin: -12px 0;

		/deep/ .van-grid-item__content {
			background-color: transparent;
		}

		position: relative;

		.grid-wrap {
			animation: myfirst 2s;
			-webkit-animation: myfirst 2s;
			animation-iteration-count: 99999;
			-webkit-animation-iteration-count: 99999;

			.main-img {
				width: 100%;
				text-align: center;
				vertical-align: middle;

				img {
					vertical-align: middle;
					width: 100%;
				}
			}

			.main-text {
				position: absolute;
				left: 0;
				bottom: 15%;
				font-size: 17px;
				font-weight: 700;
				width: 100%;
				text-align: center;
			}
		}

		@keyframes myfirst {
			0% {
				transform: scale(1);
			}
			50% {
				transform: scale(.96);
			}
			100% {
				transform: scale(1);
			}
		}
		@-webkit-keyframes myfirst {
			0% {
				transform: scale(1);
			}
			50% {
				transform: scale(.96);
			}
			100% {
				transform: scale(1);
			}
		}
	}
}
.switch_bot {
	position: fixed;
	left: 0;
	bottom: 0;
	background: #fff;
	width: 100%;
	height: 60px;

	.bot_wrap {
		margin: 10px auto;
		width: 70%;
		max-width: 400px;
		height: 40px;
		font-size: 18px;
		font-weight: 700;
		letter-spacing: 5px;

		.van-button__text {
			text-shadow: 2px 2px 2px #2a8783;
		}
	}
}

.buy_btn {
	top: 2.53333vw;
	font-size: 5.33333vw;
	position: relative;
	border: none;
	color: #6ac2f6;
	background-image: url('../../assets/vip2/subject_buy_btn.png');
	background-repeat: no-repeat;
	background-size: 100% 100%;
	background-position: center center;
	width: 92%;

	.btn_area {
		width: 100%;
		text-align: left;
		padding-left: 5px;
		padding-top: 3px
	}

	.van-button__text {
		width: 100%;
	}

	.btn-font {
		color: #fff;
		position: relative;
		top: -2px;
		font-size: 18px;
		font-weight: 700;
	}
}
</style>
